<template>
  <v-dialog v-model="active" width="500">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn icon v-on="{ ...dialogOn, ...tooltipOn }" :disabled="term===''">
            <v-icon>fal fa-smile-plus</v-icon>
          </v-btn>
        </template>
        <span>Grant grace credit for current list of {{ students.length }} students</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Grant Grace Credit</v-card-title>
      <template v-if="totalCount > 0">
        <v-progress-linear :value="completeCount / totalCount * 100"></v-progress-linear>
        <v-alert v-if="totalCount > 0" color="info" :style="isProcessing ? 'margin-bottom:0':''">
          <template v-if="isProcessing">Processing. Updated {{ completeCount }} out of {{ totalCount }} students.</template>
          <template v-else>Processing completed. All {{ totalCount }} students have been checked and {{ addedCount }} were given this credit{{ updatedCount > 0 ? ' and ' + updatedCount + ' were overridden' : '' }}.</template>
        </v-alert>
        <v-list v-if="errors.length > 0" style="max-height:400px;overflow:auto">
          <v-list-item v-for="(error, index) in errors" :key="'error-'+index">
            <v-alert color="error">{{ error }}</v-alert>
          </v-list-item>
        </v-list>
        <v-card-actions v-if="!isProcessing">
          <v-btn text @click="close(true)">Done</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          <v-autocomplete v-model="chapel" :items="chapels" label="Chapel" item-value="_id" item-text="search" outlined>
            <template v-slot:item="{ item }">
              <v-list-item-content style="max-width:400px">
                <v-list-item-title>{{ item.speaker || item.title || item.series || stringFormatDate(item.date) }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.speaker ? item.title || item.series || stringFormatDate(item.date) : (item.title ? item.series || stringFormatDate(item.date) : (item.series ? stringFormatDate(item.date) : '')) }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ (item.speaker && (item.title || item.series)) || (item.title && item.series) ? stringFormatDate(item.date) : '' }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-textarea v-model="reason" label="Description" outlined></v-textarea>
          <p>Should this credit replace any credits people already have for any of these credit types?</p>
          <v-select v-model="override" :items="overrideOptions" label="Credits to override" multiple small-chips outlined></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="close(false)">Close</v-btn>
          <v-btn text color="primary" @click="save">Save</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'
import { updateCredit } from './functions.js'

export default {
  props: {
    term: {
      type: String,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  setup (props, { root }) {
    const active = ref(false)
    const chapel = ref('')
    const chapels = ref([])
    const reason = ref('')
    const override = ref([])
    const overrideOptions = ref([ 'Swipe', 'Sign-in', 'Exemption', 'Summary', 'Removed' ])

    watch(() => props.term, () => {
      if (props.term !== '') {
        root.$feathers.service('chapel/schedule').find({ query: { term: props.term, $limit: 100, $sort: { date: 1 } } }).then(({ data }) => {
          let arr = []
          for (let i = 0; i < data.length; i++) {
            let temp = Object.assign({}, data[i])
            temp.search = ''
            if (data[i].speaker) temp.search += data[i].speaker + ' '
            if (data[i].title) temp.search += data[i].title + ' '
            if (data[i].series) temp.search += data[i].series + ' '
            temp.search += stringFormatDate(data[i].date)
            arr.push(temp)
          }
          chapels.value = arr
        })
      }
    })

    const totalCount = ref(0)
    const completeCount = ref(0)
    const addedCount = ref(0)
    const updatedCount = ref(0)
    const isProcessing = ref(false)
    const errors = ref([])

    async function save () {
      // Load the value of an exemption credit from the chapel
      const service = root.$feathers.service('chapel/student')
      // Load count of all students
      const query = { term: props.term, _id: { $in: props.students.map(({ _id }) => _id) }, $limit: 0 }
      let { total } = await service.find({ query })
      query.$limit = 50
      totalCount.value = total
      completeCount.value = 0
      isProcessing.value = true
      errors.value = []
      // Load students in blocks
      for (let i = 0; i < total; i += 50) {
        let { data } = await service.find({ query })
        // For each student, apply a $push to add this chapel (the server will take care of checking whether they have the credit already or not)
        for (let j = 0; j < data.length; j++) {
          let wasAdded = await updateCredit(chapel.value, data[j]._id, 'Exemption', reason.value, override.value)
          if (wasAdded) addedCount.value++
          completeCount.value++
        }
      }
      isProcessing.value = false
    }

    function close (clearComplete) {
      active.value = false
      if (clearComplete) {
        completeCount.value = 0
        totalCount.value = 0
      }
    }

    return {
      active,
      chapel,
      chapels,
      reason,
      override,
      overrideOptions,
      totalCount,
      completeCount,
      addedCount,
      updatedCount,
      isProcessing,
      errors,
      save,
      close,
      stringFormatDate
    }
  }
}
</script>
